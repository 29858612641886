import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
  EventOption,
  EventOptions,
  IconOption,
  Layer,
  Layers,
  MapService,
  Options,
  Order,
  OrdersService,
  ServicesService,
  TimelineService,
  OsmNominatimService,
  GeocodeSearchData
} from '@core';
import { NgxPermissionsService } from 'ngx-permissions';
import { IMyDateRangeModel } from 'angular-mydatepicker';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { slugify } from 'shared/helpers';
import { faDrawPolygon, faEraser } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { parseFromWK } from 'wkt-parser-helper';

@Component({
  selector: 'app-menu-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class LayersComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isSidebarCollapsed: boolean = false;
  @Input() isVisible: boolean = true;

  search$ = new BehaviorSubject<Options | null>(null);
  componentDestroyed$: Subject<boolean> = new Subject();
  currentCollapsedOption?: string | null;
  input?: string;
  locationFreeText?: string;
  advancedSearchParameters: Map<string, any>;

  timeseries?: string[];
  isOrdersLoading: boolean[] = [false];
  isLoading: boolean = false;
  services: IconOption[] = [];
  orders: Order[] = [];
  services$ = this.servicesService.getAllCatalogueServices();
  orders$ = this.ordersService.getAllUserOrders();
  selectedService: string | null = null;

  openService: boolean = false;
  service: { id?: string; value?: string } = {};

  isActive: { [key: string]: boolean } = {};

  advancedSearch = false;
  hasAoiDrawing = false;
  activeDialogTab: string = '';

  stylingVisible = false;
  // styleLayerId?: string;

  hasOrderAccess: boolean = true;
  hasOrderPermission: boolean = true;

  faDrawPolygon = faDrawPolygon;
  faEraser = faEraser;

  constructor(
    private ordersService: OrdersService,
    private servicesService: ServicesService,
    private mapService: MapService,
    private timelineService: TimelineService,
    private osmNominatimService: OsmNominatimService
    ) {
    this.timeseries = [];
    this.advancedSearchParameters = new Map();
  }



  ngOnDestroy(): void {
    // this.mapService.destroyAllLayers();
  }

  ngAfterViewInit(): void {

  }

  get isDrawing() {
    return this.mapService.isDrawing;
  }

  getServiceType(serviceType: string) {
    const slug = slugify(serviceType);

    return {
      isDataDisseminationService: slug === 'data-dissemination-service',
      isDataCollection: slug === 'data-collection',
      isProcessingService: slug === 'processing-service',
      isDataPreparationService: slug === 'data-preparation-service',
      isDownloadable:
        slug === ('data-dissemination-service' || 'data-collection')
    };
  }

  ngOnInit(): void {
    this.search$.subscribe(params => {
      this.services = [];
      this.orders = [];
      this.isLoading = true;
      this.servicesService
        .getAllCatalogueServices(params)
        .subscribe(services => {
          if (services) {
            services.map((service: IconOption) => {
              const { isProcessingService, isDownloadable } = this.getServiceType(
                service.serviceType
              );

              this.services.push({
                ...service,
                id: service.identifier,
                label: service.title,
                selectable: !isProcessingService,
                playable: true,
                checked: [],
                orders: [],
                downloadable: isDownloadable
              });
            });
          }
          this.isLoading = false;
        });
    });

    this.mapService.drawingLayer$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(res => {
        if (res && this.mapService.drawingContext === 'search'){
          this.hasAoiDrawing = true;
          this.advancedSearchParameters.set('aoi', res.wkt);
          this.advancedSearchRequest();
        }
      });

  }

  onChecked(
    event: EventOptions,
    layerKey: string,
    layers: Layers,
    isDataTimeSeries: boolean,
    timestamps: string[],
    wmsType: string,
    complexOrderId: number,
    orderId: number
  ) {
    if (event.target.checked) {
      if (isDataTimeSeries) {
        layers[layerKey].forEach(layer =>
          this.timelineService.addLayer({
            ...layer,
            layerKey,
            timesteps: layer.timestamps ?? [layer.dateTime],
            dataDissemination: layer.timestamps ? true : false,
            wmsType: wmsType,
            orderID: complexOrderId,
            simpleOrderId: orderId
          }, (layer.timestamps !== undefined && layer.timestamps !== null && isDataTimeSeries))
        );
      } else {
        let timesteps: string[] | undefined = undefined;
        timesteps = [];
        layers[layerKey].forEach(layer => timesteps?.push(layer.dateTime));
        layers[layerKey].forEach(layer => {
          const wmsLayer = {
            ...layer,
            layerKey,
            timesteps: timesteps ?? [],
            orderID: complexOrderId,
            simpleOrderId: orderId
          };
          this.timelineService.addLayer(wmsLayer, isDataTimeSeries);
        });
      }
      this.mapService.resetTopLayer();
      this.servicesService.addCheckedLayer(layerKey, complexOrderId, layers);
    } else {
      layers[layerKey].forEach(layer => {
        layer.opacity = undefined;
        layer.selectedStyle = undefined;
        this.mapService.destroyLayer(`${layerKey}!${complexOrderId}!${layer.dateTime}`);
        this.mapService.removeLoadedOrder(complexOrderId);
        this.timelineService.destroyLayer(layerKey, complexOrderId);
      });
      this.servicesService.removeCheckedLayer(layerKey, complexOrderId);
      this.onStyleClose();
    }
  }

  onIconClick(option: IconOption) {
    this.openService = false;

    setTimeout(() => (this.openService = true), 1);

    this.service = option;
    this.servicesService.getServicePeriod((this.service as any).identifier).pipe(take(1)).subscribe((period) => {
      (this.service as any).servicePeriod = period;
    });
    this.ordersService.checkCommercialUserPermission((this.service as any).identifier, (this.service as any).legalConstraints)
    .pipe(take(1)).subscribe({
      next: (response) => {
        this.hasOrderAccess = response.allowedAccess;
        this.hasOrderPermission = response.allowedOrder;
      },
      error: () => {
        this.hasOrderAccess = false;
        this.hasOrderPermission = false;
      }
    })
  }

  onCollapseClick(option: IconOption, index: number) {
    if (option.collapsed) {
      this.isOrdersLoading[index] = true;
      const orders: Order[] = [];

      this.servicesService.getAllUserOrdersByServiceId(option.id).subscribe(
        order => {
          orders.push(...order.rows);
          this.isOrdersLoading[index] = false;

          // if (order?.rows.length !== 0) {
          //   this.mapService.destroyAllLayers();
          //   this.timelineService.destroyAllTimesteps();
          //   this.timelineService.layersState = {};
          // }
        },
        () => (this.isOrdersLoading[index] = false)
      );
      option.orders = orders;
    }
  }

  onServiceClose() {
    this.openService = false;
    this.mapService.clearMap();
    this.service = {};
  }

  onSearchChange() {
    this.search$.next(null);
  }

  onSearchClick(event: EventOption) {
    const value = { [event.id]: event.value };
    this.search$.next(value);
  }

  onOrderMade() {
    this.onSearchChange();
    this.onServiceClose();
  }

  onOutputDownload(productID: string) {
    if (productID.includes('http')) window.open(productID);
    else
      this.ordersService
        .downloadOrderResult(productID)
        .subscribe(result => window.open(result));
  }

  isDrawingClass() {
    return {
      hidden: this.isDrawing
    };
  }

  onIdentifyClose() {
    this.mapService.removeIdentifyMarker();
  }

  toggleAdvancedSearch() {
    this.advancedSearch = !this.advancedSearch;
    if (!this.advancedSearch) {
      this.advancedSearchParameters.clear();
    }
  }

  onDateSelected(dateRange: IMyDateRangeModel) {
    const dateFormat = "yyyy-MM-dd'T'HH:mm:ss";
    if (dateRange?.beginJsDate && dateRange?.endJsDate) {
      let beginDate = format(new Date(dateRange.beginJsDate), dateFormat);
      let endDate = format(new Date(dateRange.endJsDate), dateFormat);

      this.advancedSearchParameters.set('beginDate', beginDate);
      this.advancedSearchParameters.set('endDate', endDate);
      this.advancedSearchRequest();
    }
  }

  onAreaClick() {
    this.mapService.drawOnMap('polygon', 'search');
  }

  clearMap() {
    this.mapService.clearMap();
    this.hasAoiDrawing = false;
  }

  onLocationInputChange(event: EventOption) {
    if (event.value) {
     
      const query = event.value
      
      this.osmNominatimService.geocodeSearch('json', query).then((results) => {
        if (results.data.length > 0) {
          this.geocodeSearch(results.data[0]);
        }
      }).catch((error) => console.error(error));
    }
  }

  geocodeSearch(requestData : GeocodeSearchData) {

    const bbox = requestData.boundingbox; // [minlat, maxlat, minlng, maxlng]
    const wkt = 'POLYGON((' + [[bbox[2],bbox[0]].join(' '), [bbox[3], bbox[0]].join(' '),
          [bbox[3], bbox[1]].join(' '), [bbox[2], bbox[1]].join(' '), [bbox[2],bbox[0]].join(' ')].join(',')
          + '))';
    let aoi = parseFromWK(wkt);
    this.mapService.populateMap(aoi, '#F47D08').finally();
    this.hasAoiDrawing = true;
    this.advancedSearchParameters.set('aoi', wkt);
    this.advancedSearchRequest();
      
  }

  advancedSearchRequest() {
    if (this.advancedSearch && this.advancedSearchParameters.size > 0) {
      const keys = Array.from(this.advancedSearchParameters.keys());
      let searchValue: Options = {};
      keys.forEach((key) => {
        searchValue[key] = this.advancedSearchParameters.get(key);
      })
      this.search$.next(searchValue);

    }
  }

  onTopClick(layerSimpleId: string) {
    this.mapService.bringLayerToTop(layerSimpleId);
  }

  onToggleLegendClick(layerSimpleId: string) {
    this.mapService.toggleLegend(layerSimpleId);
  }

  onZoomClicked(bbox: string) {
    this.mapService.zoomToBoundingBox(bbox);
  }

  onStyleClicked([layers, layerId, orderId]: [Layer[], string, number]) {
    this.stylingVisible = true;
    const layerKey = layerId.split('!')[0];
    const complexOrderId = parseInt(layerId.split('!')[1]);
    const layer = this.servicesService.getCheckedLayer(layerKey, complexOrderId);
    if (layer) {
      this.mapService.styleLayer = layer[layerKey][0];
      if (!this.mapService.styleLayer.layerKey) this.mapService.styleLayer.layerKey = layerKey;
      if (!this.mapService.styleLayer.orderID) this.mapService.styleLayer.orderID = complexOrderId;
      if (!this.mapService.styleLayer.simpleOrderId) this.mapService.styleLayer.simpleOrderId = orderId;
      this.mapService.styleLayerId = layerId;
    }
  }

  onStyleClose() {
    this.stylingVisible = false;
  }

  onStyleChange(newStyle: string) {
    if (this.mapService.styleLayerId) this.mapService.setLayerStyle(this.mapService.styleLayerId, newStyle);
    if (this.mapService.styleLayer) this.mapService.styleLayer.selectedStyle = newStyle;
  }

  onOpacityChange(newOpacity: number) {
    if (this.mapService.styleLayerId) this.mapService.setLayerOpacity(this.mapService.styleLayerId, newOpacity);
    if (this.mapService.styleLayer) this.mapService.styleLayer.opacity = newOpacity;
  }

  get styleLayer() {
    return this.mapService.styleLayer;
  }

  onDialogConfirm() {
    this.onServiceClose();
    this.activeDialogTab = '';
  }
}
